/**
 * Component: Accordions
 * ------------------------------------------------------------------------------
 */
import cssClasses from '../helers/cssClasses'

class Accordions {
    constructor (theme, elem) {
        this._theme = theme
        this._elem = elem

        this._settings = {
            item: '[data-accordion="item"]',
            question: '[data-accordion="question"]',
            answer: '[data-accordion="answer"]',
            ...JSON.parse(this._elem.getAttribute('data-sticky-header') || '{}')
        }

        this._nodeSelectors = {
            question: this._elem.querySelectorAll(this._settings.question),
        }
    }

    /**
     * Initialise
     */
    onInit () {
        this._setListeners()
    }

    /**
     * Set event listeners
     */
    _setListeners () {
        this._nodeSelectors.question.forEach((question) => question.addEventListener('click', (e) => this._toggleItem(e.currentTarget)))
    }

    /**
     * Toggle the active state on element
     * 
     * @param {Element} question 
     */
    _toggleItem (question) {
        const item = question.parentElement

        if (item.classList.contains(cssClasses.active)) {
            item.classList.remove(cssClasses.active)
        } else {
            item.classList.add(cssClasses.active)
        }
    }
}

export default Accordions