/**
 * Component: Treatments
 * ------------------------------------------------------------------------------
 * 
 *  
 */
import Swiper, { Thumbs, EffectFade } from 'swiper'
Swiper.use([Thumbs, EffectFade])


class Treatments {
    constructor (theme, elem) {
        this._theme = theme
        this._elem = elem
        this._settings = {
            ...JSON.parse(elem.getAttribute('data-treatments') || '{}')
        }

        this._selectors = {
            controlSwiperEl: '[data-treatments-control]',
            contentSwiperEl: '[data-treatments-content]'
        }

        this._nodeSelectors = {
            controlSwiperEl: this._elem.querySelector(this._selectors.controlSwiperEl),
            contentSwiperEl: this._elem.querySelector(this._selectors.contentSwiperEl)
        }

        this._contentSwiper = null

        this._controlSwiper = null
    }

    onInit () {
        this._initialiseSwipers()
    }

    _initialiseSwipers () {
        this._controlSwiper = new Swiper(this._nodeSelectors.controlSwiperEl, {
            slideToClickedSlide: true,
            spaceBetween: 10,
            centeredSlides: true,
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 2.5,
                },
                // when window width is >= 480px
                600: {
                    slidesPerView: 3.5,
                },
                // when window width is >= 640px
                991: {
                    slidesPerView: 4.5,
                },
                1024: {
                    centeredSlides: false,
                    allowTouchMove: false,
                    slidesPerView: 5,
                }
            },
        })

        this._contentSwiper = new Swiper(this._nodeSelectors.contentSwiperEl, {
            slidesPerView: 1,
            allowTouchMove: true,
            effect: 'fade',
            speed: 1000,
            fadeEffect: { crossFade: true },
            thumbs: {
                swiper: this._controlSwiper
            }
        })

        if(window.innerWidth > 768) {
            this._nodeSelectors.controlSwiperEl.querySelectorAll('.swiper-slide').forEach((slide, index) => {
                slide.addEventListener('mouseover', (el) => {
                    this._contentSwiper.slideTo(index)
                })
            })
        } else {
            this._contentSwiper.slideTo(1, false,false);
            this._controlSwiper.slideTo(1, false,false);
        }
    }
}

export default Treatments
