/**
 * Component: Slider
 * ------------------------------------------------------------------------------
 */
import SwiperCore, { Pagination, EffectFade, Navigation } from 'swiper/core';
SwiperCore.use([Pagination, EffectFade, Navigation])

class Slider {
    constructor (theme, elem) {
        this._theme = theme
        this._elem = elem

        this._settings = {
            pagination: false,
            prevButton: null,
            nextButton: null,
            ...JSON.parse(this._elem.getAttribute('data-slider') || '{}')
        }

        this._swiperSettings = {
            effect: 'fade',
            speed: 1000,
            fadeEffect: { crossFade: true },
            ...this._settings.swiper
        }

        this._selectors = {
            swiperEl: '.swiper-container',
            prevEl: this._settings.nextEl,
            nextEl: this._settings.prevEl
        }

        this._nodeSelectors = {
            swiperEl: this._elem.querySelector(this._selectors.swiperEl)
        }

        this._swiper = null
    }


    /**
     * Initialise
     */
    onInit () {
        this._updateSettings()

        this._swiper = new SwiperCore(this._nodeSelectors.swiperEl, this._swiperSettings)
    }

    /**
     * Create settings object from component options
     */
    _updateSettings () {
        if (this._settings.pagination === true) {
            this._swiperSettings.pagination =  {
                el: '.swiper-pagination',
                clickable: true
            }
        }

        if (this._settings.prevButton || this._settings.nextButton) {
            this._swiperSettings.navigation = {
                nextEl: this._settings.prevButton || null,
                prevEl: this._settings.nextButton || null
            }
        }
    }
}

export default Slider