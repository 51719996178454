/**
 * Component: Mobile Menu
 * ------------------------------------------------------------------------------
 * 
 *  
 */
import cssClasses from '../helers/cssClasses'

class MobileMenu {
    constructor (theme, elem) {
        this._theme = theme
        this._elem = elem

        this._settings = {
            ...JSON.parse(this._elem.getAttribute('data-mobile-menu') || '{}')
        }

        this._selectors = {
            toggle: '[data-mobile-menu-toggle]',
            items: '.mobilemenu__menu > .menu-item',
            itemsWithChildren: '.menu-item-has-children'
        }

        this._nodeSelectors = {
            toggle: document.querySelectorAll(this._selectors.toggle),
            items: document.querySelectorAll(this._selectors.items),
            itemsWithChildren: this._elem.querySelectorAll(this._selectors.itemsWithChildren)
        }
    }

    /**
     * Initialise
     */
    onInit () {
        this._setListeners()
        this._addStyles()
    }

    _setListeners () {
        this._nodeSelectors.toggle.forEach((el) => {
            el.addEventListener('click', () => this._toggleMenu())
        })

        this._nodeSelectors.itemsWithChildren.forEach((el) => {
            el.addEventListener('click', (e) => this._toggleMenuItem(e))
        })
    }

    _toggleMenu () {
        this._nodeSelectors.toggle.forEach((el) => {
            el.classList.toggle(cssClasses.active) 
        })

        this._elem.classList.toggle(cssClasses.active)

        document.body.classList.toggle(cssClasses.disableScroll)
    }

    _toggleMenuItem(e) {
        e.currentTarget.classList.toggle(cssClasses.active)
    }

    _addStyles () {
        let delay = 500
        this._nodeSelectors.items.forEach((el) => {
            el.style.transitionDelay = `${delay}ms`
            delay+=50
        })
    }
}

export default MobileMenu