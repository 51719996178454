/**
 * Component: Hero Slider
 * ------------------------------------------------------------------------------
 * 
 *  
 */
import SwiperCore, { Navigation, Controller, Pagination } from 'swiper/core';
import { EffectFade } from 'swiper';
SwiperCore.use([Navigation, Pagination, Controller, EffectFade])

class HeroSlider {
    constructor (theme, elem) {
        this._theme = theme
        this._elem = elem

        this._settings = {
            ...JSON.parse(this._elem.getAttribute('data-hero-slider') || '{}')
        }

        this._imageSlider = null

        this._contentSlider = null

        this._selectors = {
            imageSlider: '[data-hero-slider-elem="images"]',
            contentSlider: '[data-hero-slider-elem="content"]'
        }

        this._nodeSelectors = {
            imageSlider: this._elem.querySelector(this._selectors.imageSlider),
            contentSlider: this._elem.querySelector(this._selectors.contentSlider)
        }
    }

    /**
     * Initialise
     */
    onInit () {
        this._initImageSlider()
        this._initContentslider()
    }

    _initContentslider () {
        const options = {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            controller: { control: this._imageSlider },
            pagination: { 
                el: '.hero-slider__pagination',
                clickable: true
            },
            fadeEffect: { crossFade: true },
            effect: "fade",
            speed: 1000
        }

        this._contentSlider = new SwiperCore(this._nodeSelectors.contentSlider, options)
    }

    _initImageSlider () {
        const options = {
            allowTouchMove: false,
            effect: "fade",
            fadeEffect: { crossFade: true },
            speed: 1000,
        }

        this._imageSlider = new SwiperCore(this._nodeSelectors.imageSlider, options)
    }
}

export default HeroSlider