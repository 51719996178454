/**
 * Component: Sticky Header
 * ------------------------------------------------------------------------------
 */

class StickyHeader {
    constructor (theme, elem) {
        this._theme = theme
        this._elem = elem

        this._settings = {
            headerTop: '[data-sticky-header-top]',
            ...JSON.parse(this._elem.getAttribute('data-sticky-header') || '{}')
        }

        this._nodeSelectors = {
            headerTop: this._elem.querySelector(this._settings.headerTop)
        }
    }


    /**
     * Initialise
     */
    onInit () {
        this._setProperties()
        this._setListeners()
    }

    /**
     * Set initial properties
     */
    _setProperties () {
        this._headerHeight = this._elem.clientHeight
        this._headerTopHeight = this._nodeSelectors.headerTop.clientHeight
    }

    /**
     * Add event listeners
     */
    _setListeners () {
        window.addEventListener('scroll', () => this._handleScroll())
    }

    /**
     * Handle scroll event.
     */
    _handleScroll () {
        if (window.pageYOffset >= this._headerTopHeight) {
            document.body.classList.add('sticky-header-active')
            document.body.style.paddingTop = `${this._headerHeight}px`
            this._elem.style.top = `-${this._headerTopHeight}px`
        } else {
            document.body.classList.remove('sticky-header-active')
            document.body.style.paddingTop = '0px'
            this._elem.style.top = '0px'
        }
    }
}

export default StickyHeader