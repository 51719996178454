/**
 * Component: ImageSlider
 * ------------------------------------------------------------------------------
 */
import SwiperCore, {Navigation } from 'swiper/core';
SwiperCore.use([Navigation])

class ImageSlider {
    constructor (theme, elem) {
        this._theme = theme
        this._elem = elem

        this._settings = {
            prevButton: null,
            nextButton: null,
            ...JSON.parse(this._elem.getAttribute('data-image-slider') || '{}')
        }

        this._selectors = {
            swiperEl: '.swiper-container'
        }

        this._nodeSelectors = {
            swiperEl: this._elem.querySelector(this._selectors.swiperEl)
        }



        this._swiperSettings = {
            slidesPerView: 'auto',
            spaceBetween: 10,
            slidesOffsetBefore: this._getSlidesOffsetBefore(),
            slidesOffsetAfter: 15,
            breakpoints: {
                911: {
                    spaceBetween: 20,
                    slidesPerView: 'auto',
                }
            },
            navigation: {
                prevEl: this._settings.prevButton,
                nextEl: this._settings.nextButton
            }
        }

        this._swiper = null
    }


    /**
     * Initialise
     */
    onInit () {
        this._swiper = new SwiperCore(this._nodeSelectors.swiperEl, this._swiperSettings)
        this._addEventListeners()
    }

    _addEventListeners() {
        window.addEventListener('resize', () => {
            this._swiper.params.slidesOffsetBefore = this._getSlidesOffsetBefore()
            this._swiper.update()
        })
    }

    _getSlidesOffsetBefore () {
        let offset = (window.innerWidth - 1282)/2 + 15

        if (offset < 0) {
            offset = 0
        }

        return offset
    }
}

export default ImageSlider