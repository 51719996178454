import Treatments from './components/Treatments'
import Slider from './components/Slider'
import HeroSlider from './components/HeroSlider'
import StickyHeader from './components/StickyHeader'
import Accordions from './components/Accordions'
import MobileMenu from './components/MobileMenu'
import ImageSlider from './components/ImageSlider'
import ChangeClass from './components/ChangeClass'

import AOS from 'aos'
import SmoothScroll from 'smooth-scroll'

AOS.init({
    duration: 1200
})

new SmoothScroll('a[href*="#"]', {
    speed: 1200,
    updateURL: false
});

const components = {
    'slider': Slider,
    'treatments': Treatments,
    'hero-slider': HeroSlider,
    'sticky-header': StickyHeader,
    'accordions': Accordions,
    'mobile-menu': MobileMenu,
    'image-slider': ImageSlider,
    'change-class': ChangeClass
}

class Theme {
    constructor () {
        this._components = []
        this._componentsById = {}
    }

    registerComponents (container = document) {
        window.ThemeComponents = this._components
        window.ThemeComponentsId = this._componentsById
        window.Theme = this
        window.Theme = {}

        for (let key in components) {
            var ComponentConstructor = components[key]
            var elements = container.querySelectorAll(`[data-${key}]`)
            for (let elem of elements) {
                var instance = new ComponentConstructor(this, elem)
                this._components.push(instance)
                var id = elem.dataset.componentId
                if (id) {
                    this._componentsById[id] = instance
                }
                instance.onInit()
            }
        }
    }
}

const theme = new Theme()
theme.registerComponents()
